import axios, { type AxiosInstance } from 'axios';
import type React from 'react';
import { createContext, useEffect, useMemo, useRef, useState } from 'react';

export interface APIContextData {
  isSignedIn: boolean;
}

// Create the context
export const APIContext = createContext<APIContextData>({
  isSignedIn: true,
});

export const API_BASE = import.meta.env.PROD
  ? 'https://api.summittale.com'
  : import.meta.env.VITE_API_URL;

// Create the provider using the context
function APIProvider({ children }: { children: React.ReactNode }) {
  const [isSignedIn, setIsSignedIn] = useState(false); // Initially, user is considered signed in

  const axiosInstance = useRef<AxiosInstance>(
    axios.create({
      baseURL: API_BASE,
      headers: {
        'Content-type': 'application/json',
      },
      withCredentials: true,
    }),
  );

  useEffect(() => {
    const interceptor = axiosInstance.current.interceptors.response.use(
      (response) => {
        if (response.status === 200) {
          setIsSignedIn(true);
        }
        return response;
      },
      (error) => {
        // Unauthorized (invalid credentials or no credentials), different from 403 which says you are signed in but don't have permission
        if (error.response.status === 401) {
          setIsSignedIn(false);
        }
        return Promise.reject(error);
      },
    );

    return () => {
      axiosInstance.current.interceptors.response.eject(interceptor);
    };
  }, []);

  // Trigger a request to check if user is signed in
  useEffect(() => {
    axiosInstance.current.get('/athlete').then(() => {
      setIsSignedIn(true);
    });
  }, []);

  const apiContextValue = useMemo(
    () => ({
      isSignedIn,
    }),
    [isSignedIn],
  );

  return (
    <APIContext.Provider value={apiContextValue}>
      {children}
    </APIContext.Provider>
  );
}

export default APIProvider;
