import axios from 'axios';

// TODO: Read from env after init script is created
export const API_BASE = import.meta.env.PROD
  ? 'https://api.summittale.com'
  : import.meta.env.VITE_API_URL;

const axiosInstance = axios.create({
  baseURL: API_BASE,
  headers: { 'Content-Type': 'application/json' },
  withCredentials: true,
});

export default axiosInstance;
