import { type ColumnDef, createColumnHelper } from '@tanstack/react-table';

import { useGetRegions, useUploadRegionImage } from '../../hooks/api/regions';
import type { RegionDTO } from '../../utils/apiTypes';
import AdminTable from './adminTable';

export default function Regions() {
  const regionsQuery = useGetRegions({
    includeGeometry: false,
  });

  const columnHelper = createColumnHelper<RegionDTO>();

  const uploadRegionImage = useUploadRegionImage();

  const columns = [
    columnHelper.accessor('id', { header: 'ID' }),
    columnHelper.accessor('name', { header: 'Name' }),
    columnHelper.accessor('description', { header: 'Description' }),
    columnHelper.accessor('type', {
      header: 'Type',
    }),
    columnHelper.accessor('isocode', {
      header: 'ISO Code',
      cell: (props) => {
        const isocode = props.getValue();
        return (
          <div className="flex items-center">
            {isocode && (
              <>
                <span className={`fi fi-${isocode.toLocaleLowerCase()}`} />
                <span className="ml-3">{isocode}</span>
              </>
            )}
          </div>
        );
      },
    }),
    columnHelper.accessor('imageUrl', {
      header: 'Image',
      cell: (props) => (
        <div className="flex items-center">
          {props.getValue() && (
            <div className="w-6 h-6">
              <img
                src={props.getValue()}
                alt={props.row.original.name}
                className="h-6"
              />
            </div>
          )}
          <input
            type="file"
            onChange={(e) => {
              const file = e.target.files?.[0];
              if (file) {
                uploadRegionImage.mutate({ id: props.row.original.id, file });
              }
            }}
          />
        </div>
      ),
    }),
  ];

  if (regionsQuery.isLoading) {
    return <div>Loading...</div>;
  }
  if (regionsQuery.isError) {
    return <div>Error</div>;
  }

  return (
    <AdminTable
      columns={columns as ColumnDef<RegionDTO>[]}
      data={regionsQuery.data || []}
    />
  );
}
