import { type ColumnDef, createColumnHelper } from '@tanstack/react-table';
import { FaMountain } from 'react-icons/fa';
import { ImLocation } from 'react-icons/im';

import { useGetFeatures } from '@/hooks/api';
import { useGetRegions } from '../../hooks/api/regions';
import type { Feature } from '../../utils/apiTypes';
import AdminTable from './adminTable';

export default function Features() {
  const featuresQuery = useGetFeatures({});
  const regionQuery = useGetRegions({ includeGeometry: false });

  const columnHelper = createColumnHelper<Feature>();

  const columns = [
    columnHelper.accessor('id', { header: 'ID' }),
    columnHelper.accessor('name', { header: 'Name' }),
    columnHelper.accessor('type', {
      cell: (props) => (
        <div className="flex items-center">
          {props.getValue() === 'PEAK' && (
            <>
              <FaMountain className="w-4 h-4 text-gray-500 transition duration-75 dark:text-gray-400 group-hover:text-gray-900 dark:group-hover:text-white" />
              <span className="ml-3">Peak</span>
            </>
          )}
          {props.getValue() === 'POINT' && (
            <>
              <ImLocation className="w-4 h-4 text-gray-500 transition duration-75 dark:text-gray-400 group-hover:text-gray-900 dark:group-hover:text-white" />
              <span className="ml-3">Point</span>
            </>
          )}
        </div>
      ),
      header: 'Type',
    }),
    columnHelper.accessor('latitude', {
      cell: (props) => (
        <div className="text-right font-mono">
          {props.getValue().toFixed(5)}
        </div>
      ),
      header: 'Latitude',
    }),
    columnHelper.accessor('longitude', {
      cell: (props) => (
        <div className="text-right font-mono">
          {props.getValue().toFixed(5)}
        </div>
      ),
      header: 'Longitude',
    }),
    columnHelper.accessor('elevation', {
      cell: (props) => (
        <div className="text-right font-mono">
          {props.getValue()?.toFixed(1)} m
        </div>
      ),
      header: 'Elevation',
    }),
    columnHelper.accessor('prominence', {
      cell: (props) => (
        <div className="text-right font-mono">
          {props.getValue()?.toFixed(1)} m
        </div>
      ),
      header: 'Prominence',
    }),
    columnHelper.accessor('isolation', {
      cell: (props) => (
        <div className="text-right font-mono">
          {props.getValue()?.toFixed(1)} km
        </div>
      ),
      header: 'Isolation',
    }),
    columnHelper.accessor('regions', {
      cell: (props) => {
        if (!props.getValue()) {
          return <div />;
        }
        const region = regionQuery.data?.find(
          (region) => region.id === props.getValue()[0].id,
        );
        return <div>{region?.name}</div>;
      },
      header: 'Region',
    }),
    columnHelper.accessor('notes', { header: 'Notes' }),
  ];

  if (featuresQuery.isLoading) {
    return <div>Loading...</div>;
  }
  if (featuresQuery.isError) {
    return <div>Error</div>;
  }

  return (
    <AdminTable
      columns={columns as ColumnDef<Feature>[]}
      data={featuresQuery.data || []}
    />
  );
}
