import 'flag-icons/css/flag-icons.min.css';
import 'maplibre-gl/dist/maplibre-gl.css';
import './index.css';

import { QueryClientProvider } from '@tanstack/react-query';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools';
import ReactDOM from 'react-dom/client';
import { ErrorBoundary } from 'react-error-boundary';
import { Helmet, HelmetProvider } from 'react-helmet-async';
import { RouterProvider, createBrowserRouter } from 'react-router-dom';

import APIProvider from './apiProvider';
import ErrorFallback from './components/errorFallback';
import { queryClient } from './hooks/useApi';
import Admin from './routes/admin';
import Athletes from './routes/admin/athletes';
import Features from './routes/admin/features';
import Regions from './routes/admin/regions';
import WorkerQueue from './routes/admin/workerQueue';
import App from './routes/app';

import * as Sentry from '@sentry/react';
import React from 'react';

Sentry.init({
  dsn: 'https://71f66d398791a1bf862f4b4458283881@o4506783751340032.ingest.sentry.io/4506783752978432',
  integrations: [
    Sentry.browserTracingIntegration(),
    Sentry.replayIntegration({
      maskAllText: false,
      blockAllMedia: false,
    }),
  ],
  // Performance Monitoring
  tracesSampleRate: 1.0, //  Capture 100% of the transactions
  // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
  tracePropagationTargets: ['localhost', /^https:\/\/yourserver\.io\/api/],
  // Session Replay
  replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
  replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
});

const router = createBrowserRouter([
  {
    path: '/*',
    element: <App />,
  },
  {
    path: 'admin/*',
    element: <Admin />,
    children: [
      {
        path: 'features',
        element: <Features />,
      },
      {
        path: 'athletes',
        element: <Athletes />,
      },
      {
        path: 'regions',
        element: <Regions />,
      },
      {
        path: 'queue',
        element: <WorkerQueue />,
      },
      {
        path: '*',
        element: <div>Not found</div>,
      },
    ],
  },
]);

ReactDOM.createRoot(document.getElementById('root') as HTMLElement).render(
  <React.StrictMode>
    <ErrorBoundary FallbackComponent={ErrorFallback}>
      <HelmetProvider>
        <Helmet>
          <title>Summit Tale</title>
        </Helmet>
        <APIProvider>
          <QueryClientProvider client={queryClient}>
            <RouterProvider router={router} />
            <ReactQueryDevtools initialIsOpen={false} />
          </QueryClientProvider>
        </APIProvider>
      </HelmetProvider>
    </ErrorBoundary>
  </React.StrictMode>,
);
