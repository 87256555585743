import {
  FaArrowLeft,
  FaFlag,
  FaHardHat,
  FaHiking,
  FaList,
  FaMountain,
} from 'react-icons/fa';
import { Link, Outlet } from 'react-router-dom';

export default function Admin() {
  return (
    <>
      <div className="fixed top-0 left-0 z-40 w-64 h-screen">
        <nav className="h-full px-3 py-4 overflow-y-auto bg-slate-50 dark:bg-slate-800 flex flex-col gap-10">
          <Link
            to="/"
            className="flex items-center p-2 text-gray-900 rounded-lg dark:text-white hover:bg-gray-100 dark:hover:bg-gray-700"
          >
            <FaArrowLeft className="w-6 h-6 text-gray-500 transition duration-75 dark:text-gray-400 group-hover:text-gray-900 dark:group-hover:text-white" />
            <span className="ml-3">Home</span>
          </Link>
          <div className="flex flex-col gap-2">
            <ul className="space-y-2 font-medium">
              <li>
                <Link
                  to="athletes"
                  className="flex items-center p-2 text-gray-900 rounded-lg dark:text-white hover:bg-gray-100 dark:hover:bg-gray-700"
                >
                  <FaHiking className="w-6 h-6 text-gray-500 transition duration-75 dark:text-gray-400 group-hover:text-gray-900 dark:group-hover:text-white" />
                  <span className="ml-3">Athletes</span>
                </Link>
              </li>
              <li>
                <Link
                  to="regions"
                  className="flex items-center p-2 text-gray-900 rounded-lg dark:text-white hover:bg-gray-100 dark:hover:bg-gray-700"
                >
                  <FaFlag className="w-6 h-6 text-gray-500 transition duration-75 dark:text-gray-400 group-hover:text-gray-900 dark:group-hover:text-white" />
                  <span className="ml-3">Regions</span>
                </Link>
              </li>
              <li>
                <Link
                  to="features"
                  className="flex items-center p-2 text-gray-900 rounded-lg dark:text-white hover:bg-gray-100 dark:hover:bg-gray-700"
                >
                  <FaMountain className="w-6 h-6 text-gray-500 transition duration-75 dark:text-gray-400 group-hover:text-gray-900 dark:group-hover:text-white" />
                  <span className="ml-3">Features</span>
                </Link>
              </li>
              <li>
                <Link
                  to="lists"
                  className="flex items-center p-2 text-gray-900 rounded-lg dark:text-white hover:bg-gray-100 dark:hover:bg-gray-700"
                >
                  <FaList className="w-6 h-6 text-gray-500 transition duration-75 dark:text-gray-400 group-hover:text-gray-900 dark:group-hover:text-white" />
                  <span className="ml-3">Lists</span>
                </Link>
              </li>
            </ul>
          </div>
          <div className="flex flex-col gap-2">
            <Link
              to="queue"
              className="flex items-center p-2 text-gray-900 rounded-lg dark:text-white hover:bg-gray-100 dark:hover:bg-gray-700"
            >
              <FaHardHat className="w-6 h-6 text-gray-500 transition duration-75 dark:text-gray-400 group-hover:text-gray-900 dark:group-hover:text-white" />
              <span className="ml-3">Worker Queue</span>
            </Link>
          </div>
        </nav>
      </div>
      <div className="p-4 ml-64 ">
        <Outlet />
      </div>
    </>
  );
}
