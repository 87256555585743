import { useMutation, useQuery } from '@tanstack/react-query';

import apiClient from '../../utils/apiClient';
import type { RegionDTO, RegionType } from '../../utils/apiTypes';
import { queryClient } from '../useApi';

export const useGetRegions = (options: {
  parentRegionId?: number;
  regionType?: RegionType;
  includeGeometry?: boolean;
}) =>
  useQuery({
    queryKey: ['regions', options],
    queryFn: () =>
      apiClient
        .get<RegionDTO[]>('/regions', {
          params: options,
        })
        .then((res) => res.data),
  });

export const useGetRegion = (regionId: number) =>
  useQuery({
    queryKey: ['region', regionId],
    queryFn: () =>
      apiClient.get<RegionDTO>(`/regions/${regionId}`).then((res) => res.data),
    // Load initial data from all regions list
    initialData: () => {
      const regions = queryClient.getQueryData<RegionDTO[]>([
        'regions',
        undefined,
      ]);
      return regions?.find((region: RegionDTO) => region.id === regionId);
    },
  });

export const useAddRegion = () =>
  useMutation({
    mutationFn: ({ ...data }: RegionDTO) => apiClient.post('/regions', data),
    onSuccess: (data) => {
      queryClient.setQueryData(['region', data.data.id.toString()], data.data);
      queryClient.setQueryData(
        ['regions', data.data.type],
        (oldData: RegionDTO[]) => [...oldData, data.data],
      );
      queryClient.setQueryData(
        ['regions', undefined],
        (oldData: RegionDTO[]) => [...oldData, data.data],
      );
    },
  });

export const useUpdateRegion = () =>
  useMutation({
    mutationFn: ({ id, ...data }: RegionDTO) =>
      apiClient.put<RegionDTO>(`/regions/${id}`, data),
    onSuccess: (data, variables) => {
      // Update region
      queryClient.setQueryData(['region', variables.id.toString()], data);

      // Remove old region from list
      queryClient.setQueryData(
        ['regions', variables.type],
        (oldData: RegionDTO[]) =>
          oldData.filter((d: RegionDTO) => d.id !== variables.id),
      );

      // Add new region to list
      queryClient.setQueryData(
        ['regions', data.data.type],
        (oldData: RegionDTO[]) =>
          oldData.map((d: RegionDTO) => (d.id === variables.id ? data : d)),
      );

      // Update all regions list
      queryClient.setQueryData(['regions', undefined], (oldData: RegionDTO[]) =>
        oldData.map((d: RegionDTO) => (d.id === variables.id ? data : d)),
      );
    },
  });

export const useDeleteRegion = () =>
  useMutation({
    mutationFn: (data: RegionDTO) =>
      apiClient.delete<void>(`/regions/${data.id}`),
    onSuccess: (_, variables) => {
      // Remove region
      queryClient.removeQueries({
        queryKey: ['region', variables.id.toString()],
      });

      // Remove region from list
      queryClient.setQueryData(['regions', undefined], (oldData: RegionDTO[]) =>
        oldData.filter((d: RegionDTO) => d.id !== variables.id),
      );

      // Remove region from all regions list
      queryClient.setQueryData(
        ['regions', variables.type],
        (oldData: RegionDTO[]) =>
          oldData.filter((d: RegionDTO) => d.id !== variables.id),
      );
    },
  });

export const useUploadRegionImage = () =>
  useMutation({
    mutationFn: (data: { id: number; file: File }) => {
      const formData = new FormData();
      console.log(data.file);
      formData.append('file', data.file);

      return apiClient.post<RegionDTO>(`/regions/${data.id}/image`, formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      });
    },
    onSuccess: (data, variables) => {
      // Update region
      queryClient.setQueryData(['region', variables.id.toString()], data.data);

      // Add new region to list
      queryClient.setQueryData(
        ['regions', data.data.type],
        (oldData: RegionDTO[]) =>
          oldData.map((d: RegionDTO) => (d.id === variables.id ? data : d)),
      );

      // Update all regions list
      queryClient.setQueryData(['regions', undefined], (oldData: RegionDTO[]) =>
        oldData.map((d: RegionDTO) => (d.id === variables.id ? data : d)),
      );
    },
  });
