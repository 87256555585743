import { type ColumnDef, createColumnHelper } from '@tanstack/react-table';

import { useGetAthletes } from '../../hooks/api';
import type { Athlete } from '../../utils/apiTypes';
import AdminTable from './adminTable';

export default function Athletes() {
  const athletesQuery = useGetAthletes();

  const columnHelper = createColumnHelper<Athlete>();

  const columns = [
    columnHelper.accessor('profileMedium', {
      cell: (props) => (
        <img alt="" className="w-8 h-8 rounded-full" src={props.getValue()} />
      ),
      header: undefined,
    }),
    columnHelper.accessor('id', { header: 'ID' }),
    columnHelper.accessor('username', { header: 'Username' }),
    columnHelper.accessor('firstname', { header: 'First Name' }),
    columnHelper.accessor('lastname', { header: 'Last Name' }),
    columnHelper.accessor('role', { header: 'Role' }),
    columnHelper.accessor('stravaLastRefreshedAt', {
      header: 'Strava Last Refreshed',
      cell: (props) => {
        const value = props.getValue();
        return value ? new Date(value).toLocaleString() : 'Never';
      },
    }),
  ];

  if (athletesQuery.isLoading) {
    return <div>Loading...</div>;
  }
  if (athletesQuery.isError) {
    return <div>Error</div>;
  }

  return (
    <AdminTable
      columns={columns as ColumnDef<Athlete>[]}
      data={athletesQuery.data || []}
    />
  );
}
