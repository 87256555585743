import { useState } from 'react';

import { useQueueEvent } from '../../hooks/useApi';

export default function WorkerQueue() {
  const queueEvent = useQueueEvent();

  const [data, setData] = useState<string>('');

  return (
    <div className="flex flex-col gap-4">
      <textarea
        className="w-full h-64 bg-slate-700 text-white"
        value={data}
        onChange={(e) => setData(e.target.value)}
      />
      <button
        onClick={() => {
          queueEvent.mutate({ type: 'test', data });
        }}
        className="px-4 py-2 bg-slate-700 text-white rounded-lg hover:bg-slate-600"
        disabled={queueEvent.isPending}
        type="button"
      >
        {queueEvent.isPending ? 'Queueing...' : 'Queue event'}
      </button>
    </div>
  );
}
