import {
  QueryClient,
  type UseQueryResult,
  useMutation,
  useQuery,
} from '@tanstack/react-query';

import apiClient from '../utils/apiClient';
import type { Feature, List } from '../utils/apiTypes';

export const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      staleTime: 0,
      refetchOnWindowFocus: true,
    },
  },
});

export const useSearch = (
  query: string,
): UseQueryResult<{ lists: List[]; features: Feature[] }, unknown> =>
  useQuery({
    queryKey: ['search', query],
    queryFn: ({ signal }) =>
      apiClient.get(`/search?q=${query}`, { signal }).then((res) => res.data),
    enabled: query !== undefined && query !== '',
    staleTime: 0,
  });

export const useQueueEvent = () =>
  useMutation({
    mutationFn: (data: { type: string; data: unknown }) =>
      apiClient.post('/queue', data),
  });
