import { Suspense, lazy } from 'react';
import { ErrorBoundary } from 'react-error-boundary';

import ErrorFallback from '../components/errorFallback';
import LoadingSpinner from '../components/loadingSpinner';

// Define lazy-loaded components
const Mapplication = lazy(() => import('./mapplication'));

function App() {
  return (
    <ErrorBoundary FallbackComponent={ErrorFallback}>
      <Suspense fallback={<LoadingSpinner />}>
        <Mapplication />
      </Suspense>
    </ErrorBoundary>
  );
}

export default App;
