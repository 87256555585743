import { queryClient } from '@/hooks/useApi';
import apiClient from '@/utils/apiClient';
import type {
  CreateFeatureDTO,
  DataSourceFeature,
  Feature,
  GetAllFeaturesQuery,
  UpdateFeatureDTO,
} from '@/utils/apiTypes';
import {
  type UseMutationResult,
  keepPreviousData,
  useMutation,
  useQuery,
} from '@tanstack/react-query';

export const useUpdateFeature = (): UseMutationResult<
  Feature,
  unknown,
  UpdateFeatureDTO
> =>
  useMutation({
    mutationFn: ({ id, ...data }) =>
      apiClient.put(`/features/${id}`, data).then((res) => res.data),
    onSuccess: (_data, variables) => {
      queryClient.invalidateQueries({
        queryKey: ['feature', variables.id],
      });
    },
  });

export const useGetFeature = (id: number | undefined) =>
  useQuery({
    queryKey: ['feature', id],
    queryFn: () =>
      apiClient.get<Feature>(`/features/${id}`).then((res) => res.data),
    enabled: id !== undefined,
  });

export const usePrefetchFeature = (id: number) =>
  queryClient.prefetchQuery({
    queryKey: ['feature', id],
    queryFn: () =>
      apiClient.get<Feature>(`/features/${id}`).then((res) => res.data),
  });

export const useCreateFeature = () =>
  useMutation({
    mutationFn: (data: CreateFeatureDTO) =>
      apiClient.post<Feature>('/features', data).then((res) => res.data),
    onSuccess: (data) => {
      queryClient.setQueryData(['feature', data.id], data);
    },
  });

export const useGetFeatures = (data: GetAllFeaturesQuery) =>
  useQuery({
    queryKey: ['features', data],
    queryFn: () =>
      apiClient.get<Feature[]>('/features', { params: data }).then((res) => {
        // Update cache with features
        for (const feature of res.data) {
          queryClient.setQueryData<Feature>(
            ['feature', feature.id],
            (oldFeature) => {
              if (oldFeature) {
                return { ...oldFeature, ...feature };
              }
              return feature;
            },
          );
        }

        return res.data;
      }),
    placeholderData: keepPreviousData,
  });

export const useGetSimilarFeatures = (id: number) =>
  useQuery({
    queryKey: ['similarFeatures', id],
    queryFn: () =>
      apiClient
        .get<
          {
            feature: Feature | DataSourceFeature;
            score: number;
            factors: {
              location: number;
              name?: number;
              type?: number;
              elevation?: number;
              prominence?: number;
            };
          }[]
        >(`/features/${id}/similar`)
        .then((res) => res.data),
  });
